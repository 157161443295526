import _ from 'lodash'
import React, { Component, memo } from 'react'
import { Search, Grid, Header, Segment } from 'semantic-ui-react'
import productGroups from '../data/productGroups.json'
import products from '../data/products.json'
import sizes from '../data/sizes.json'

const initialState = { isLoading: false, results: [], value: '' }

const source = sizes.map((p) => ({
  key: p.code,
  title: products.find(pr => pr.id === p.productId)?.title ?? "",
  image: p.image,
  description: p.text + ", " + p.code,
  name: productGroups.find(pg => pg.id === products.find(pr => pr.id === p.productId).productGroupId).title,
  productGroupId: products.find(pr => pr.id === p.productId).productGroupId
}))


export class SearchBar extends Component {
  state = initialState

  navigate = (productGroupId) => {
    console.log(productGroupId);
    switch (productGroupId) {
      case 1:
      case 2:
      case 3:
        return "/Faegtebeklaedning"
      case 4:
      case 5:
      case 6:
        return "/Elveste"
      case 7:
      case 8:
      case 9:
        return "/Sikkerhedstoej"
      case 10:
        return "/Handsker"
      case 11:
      case 12:
        return "/Faegtesko"
      case 13:
      case 14:
      case 15:
      case 16:
      case 17:
        return "/Masker"
      case 18:
      case 19:
        return "/Fleuret"
      case 20:
      case 21:
        return "/Kaarde"
      case 22:
      case 23:
        return "/Sabel"
      case 24:
      case 25:
      case 26:
      case 27:
        return "/Tilbehoer-Fleuret"
      case 28:
      case 29:
      case 30:
        return "/Tilbehoer-Kaarde"
      case 31:
      case 32:
        return "/Tilbehoer-Sabel"
      case 33:
      case 34:
      case 35:
        return "/Diverse-Diverseogfaegtetasker"
      case 36:
        return "/Diverse-Faegtemesterudstyr"
      case 37:
      case 38:
        return "/Diverse-Faegtepisteogtilbehoer"
      case 39:
        return "/Diverse-Meldeapparater"
      case 40:
      case 41:
        return "/Diverse-Diverseogfaegtetasker"
      default:
        return "/";
    }
  }

  handleResultSelect = (e, { result }) => window.open(this.navigate(result.productGroupId), "_self");

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value })

    setTimeout(() => {
      if (this.state.value.length < 1) return this.setState(initialState)

      const re = new RegExp(_.escapeRegExp(this.state.value), 'i')
      const isMatch = (result) => re.test(result)
      var filteredResults = [];


      source.filter(p => isMatch(p.description) || isMatch(p.title)).slice(0, 10).forEach(element => {
        const res = {
          key: element.key,
          title: element.title,
          image: element.image,
          description: element.description,
          productGroupId: element.productGroupId
        }
        if (filteredResults.find(fr => fr.name === element.name)) {
          filteredResults.find(fr => fr.name === element.name).results.push(res);
        } else {

          filteredResults.push({
            name: element.name,
            results: [res]
          })
        }
      });

      console.log(filteredResults);

      this.setState({
        isLoading: false,
        results: filteredResults,
      })
    }, 300)
  }

  render() {
    const { isLoading, value, results } = this.state
    return (
      <Search
        className="search-bar"
        category
        onResultSelect={this.handleResultSelect}
        onSearchChange={_.debounce(this.handleSearchChange, 500, {
          leading: true,
        })}
        results={results}
        value={value}
        size='large'
      />
    )
  }
}

