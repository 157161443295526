import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Menu, Dropdown, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { SearchBar } from './common/SearchBar';

export class Layout extends Component {
  static displayName = Layout.name;

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <div>
        <Menu style={{innerHeight:'50px'}}>
          <Link to="/">
            <Menu.Item name='Allstar DK'>
              ALLSTAR-DANMARK
            </Menu.Item>
          </Link>
          <Dropdown item text='Beklædning'>
            <Dropdown.Menu>
              <Dropdown.Item>
                <Link to="/Faegtebeklaedning">
                  <Menu.Item name='Fægtebeklædning'>
                  Fægtebeklaedning
                  </Menu.Item>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link to="/Sikkerhedstoej">
                  <Menu.Item name='Sikkerhedstøj'>
                  Sikkerhedstøj
                  </Menu.Item>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link to="/Elveste">
                  <Menu.Item name='Elveste'>
                  Elveste
                  </Menu.Item>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link to="/Handsker">
                  <Menu.Item name='Handsker'>
                  Handsker
                  </Menu.Item>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link to="/Faegtesko">
                  <Menu.Item name='Fægtesko'>
                  Fægtesko
                  </Menu.Item>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link to="/Masker">
                  <Menu.Item name='Masker'>
                  Masker
                  </Menu.Item>
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown item text='Våben'>
            <Dropdown.Menu>
              <Dropdown.Item>
                <Link to="/Fleuret">
                  <Menu.Item name='Fleuret'>
                  Fleuret
                  </Menu.Item>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link to="/Kaarde">
                  <Menu.Item name='Kårde'>
                  Kårde
                  </Menu.Item>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link to="/Sabel">
                  <Menu.Item name='Sabel'>
                  Sabel
                  </Menu.Item>
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown item text='Våbentilbehør'>
            <Dropdown.Menu>
              <Dropdown.Item>
                <Link to="/Tilbehoer-Fleuret">
                  <Menu.Item name='Fleuret'>
                  Fleuret
                  </Menu.Item>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link to="/Tilbehoer-Kaarde">
                  <Menu.Item name='Kårde'>
                  Kårde
                  </Menu.Item>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link to="/Tilbehoer-Sabel">
                  <Menu.Item name='Sabel'>
                  Sabel
                  </Menu.Item>
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown item text='Diverse'>
            <Dropdown.Menu>
              <Dropdown.Item>
                <Link to="/Diverse-Diverseogfaegtetasker">
                  <Menu.Item name='Diverse og Fægtetasker'>
                  Diverse og Fægtetasker
                  </Menu.Item>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link to="/Diverse-Faegtemesterudstyr">
                  <Menu.Item name='Fægtemesterudstyr'>
                  Fægtemesterudstyr
                  </Menu.Item>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link to="/Diverse-Faegtepisteogtilbehoer">
                  <Menu.Item name='Faegtepiste og Tilbehør'>
                  Faegtepiste og Tilbehør
                  </Menu.Item>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link to="/Diverse-Meldeapparater">
                  <Menu.Item name='Meldeapparater'>
                  Meldeapparater
                  </Menu.Item>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link to="/Diverse-Hvadboerennybegynderhave">
                  <Menu.Item name='Hvad bør en nybegynder have?'>
                  Hvad bør en nybegynder have?
                  </Menu.Item>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link to="/Diverse-Salgsbetingelser">
                  <Menu.Item name='Salgsbetingelser'>
                  Salgsbetingelser
                  </Menu.Item>
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <SearchBar />
        </Menu>

        <Container style={{ margin: '28px' }}>
          {this.props.children}
        </Container>
        <Segment size='massive'>ALLSTAR-DANMARK</Segment>
      </div>
    );
  }
}
