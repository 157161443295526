import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';

export class Sikkerhedstoej extends Component {
  static displayName = Sikkerhedstoej.name;

  render() {
    return (
      <div>
          <ProductOverview Id={7} />
        <div style={{ width: '100%', textAlign: 'center' }}>
          <img src="./sikkerhedstoej/storrelser.png" alt="GrTab_Plastron" />
        </div>
        <ProductOverview Id={9} />
        <ProductOverview Id={8} />
      </div>
    );
  }
}
