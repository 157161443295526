import React, { Component } from 'react';
import { Grid, Image } from 'semantic-ui-react'
import productGroups from '../data/productGroups.json'
import products from '../data/products.json'
import sizes from '../data/sizes.json'
import prices from '../data/price_latest.json'

export class ProductOverview extends Component {
  factor = 11.6;

  constructor(props) {
    super(props);
    this.state = {
      id: this.props.Id
    };
  }

  getSize(id, text, code, price, notes) {
    let newPriceLine = prices.find(p => p['Ref.'] === code)
    let tempPrice = price;
    let finalPriceString = price + "*"
    if (newPriceLine) {
      let temptemp = parseFloat(newPriceLine['Net Price NEW'].replace(".","").replace(",","."));
       tempPrice = Math.ceil(temptemp * this.factor / 5) * 5;
       finalPriceString = "" + tempPrice
    }

    finalPriceString = finalPriceString.replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ",-";

    return (
      <Grid.Row key={id}>
        <Grid.Column width={6}>
          {text}
        </Grid.Column>
        <Grid.Column width={2}>
          {code}
        </Grid.Column>
        <Grid.Column width={4} style={{textAlign: 'right'}}>
          DKK {finalPriceString}
        </Grid.Column>
        <Grid.Column width={4}>
          {notes}
        </Grid.Column>
      </Grid.Row>
    );
  }

  getSizes(sizes) {
    let id = 1;
    return (
      <Grid>
        {sizes.map(size => this.getSize(id++, size.text, size.code, size.price, size.notes))}
      </Grid>
    );
  }

  getRow(id, image, title, description) {
    let productSizes = sizes.filter(size => size.productId === id)
    return (
      <Grid.Row key={id}>
        <Grid.Column width={3}>
          <div className="center-image">
            <Image src={image} />
          </div>
        </Grid.Column>
        <Grid.Column width={3}>
          <h5>{title}</h5>
          {description}
        </Grid.Column>
        <Grid.Column width={10}>
          {this.getSizes(productSizes)}
        </Grid.Column>
      </Grid.Row>
    );
  }

  render() {
    let productGroup = productGroups.find(pg => pg.id === this.state.id)
    let productGroupProducts = products.filter(p => p.productGroupId === productGroup.id);
    return (
      <div>
        <h3>{productGroup.title}</h3>
        <Grid celled>
          {productGroupProducts.map(product => this.getRow(product.id, product.Image, product.Title, product.Description))}
        </Grid>
      </div>
    );
  }
}
