import React, { Component } from 'react';
import { Image } from 'semantic-ui-react';

export class Hvadboerennybegynderhave extends Component {
  static displayName = Hvadboerennybegynderhave.name;

  render() {
    return (
      <div>
        <h3>Hvad en nybegynder bør have? Se priserne i listen, ikke på fotoet </h3>
        <Image src="nybegynder/nybegynder.jpg" alt="Hvad en nybegynder bør have" ></Image>
        <h3>Prisindikationer. Tjek priserne i prislisten.</h3>
      </div>
    );
  }
}
