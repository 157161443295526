import React, { Component } from 'react';
import empng from '../images/forside-EM.png';

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div className="container">
        <div className="col-xs-12 col-sm-12 col-md-12">
          <br />
          <div className="col-xs-12 col-sm-12 col-md-12">
            <h2 style={{ textAlign: "center" }}> Velkommen til Allstar-Danmark - nr. 1 inden for fægteudstyr </h2>
            <h3 style={{ textAlign: "center" }}>Officiel sponsor for fægtere og fægtebegivenheder <br />
              <br />
            </h3>

            <div className="col-xs-12 col-sm-12 col-md-12">
              <p style={{ textAlign: "center" }}>
                <b>Fægteudstyr på lager i Hellerup Fægte-Klub </b><br /><br />
                Strandparksvej 46, 1, 2900 Hellerup <br />
                Att.: Hans H. von der Osten, Mobil: 20 33 39 44 <br />
                Tel: 39 62 39 44, mail: <a href="mailto:hans@visiodan.dk" target="_top"> hans@visiodan.dk
                </a>
              </p>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-3"></div>
            <div className="col-xs-12 col-sm-12 col-md-6">
              <hr style={{ textAlign: "center" }}>
              </hr>
              <div className="col-xs-12 col-sm-12 col-md-12" style={{ textAlign: "center" }}>
                <p>Hans er normalt på salen tirsdage og torsdage mellem ca. 19.30-21.30<br />
                  Det bedste er at ringe eller sende en SMS til 20 33 39 44 og aftale tid
                  <br /><br />
                  Betaling: MobilePay til 57953 Allstar-Danmark eller med kort <br />
                </p><br />
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12">
              <h2 style={{ textAlign: "center" }}> Senior EM Herre Kårde Sølv 2019 </h2>
              <div className="col-xs-12 col-sm-12 col-md-3">
                <img src={empng} style={{ alignSelf: 'center' }} alt="Senior EM Herre Kårde Sølv 2019" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
