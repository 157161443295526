import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';

export class Faegtesko extends Component {
  static displayName = Faegtesko.name;

  render() {
    return (
      <div>
        <ProductOverview Id={11} />
        <ProductOverview Id={12} />
        <div style={{ width: '100%', textAlign: 'center' }}>
          <img src="./faegtesko/skostorrelser.png" alt="sko-storrelser" />
        </div>
      </div>
    );
  }
}
