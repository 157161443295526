import React, { Component } from 'react';
import { ProductOverview } from '../common/ProductOverview';

export class Elveste extends Component {
  static displayName = Elveste.name;

  render() {
    return (
      <div>
        <ProductOverview Id={4} />
        <ProductOverview Id={5} />
        <ProductOverview Id={6} />
      </div>
    );
  }
}
